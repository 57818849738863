import axios from 'axios';

// ----------------------------------------------------------------------

const Server = 'https://tokenstore.fgp.digital';

const accessToken = window.localStorage.getItem('accessToken');

const axiosInstance = axios.create({
  baseURL: Server,
  headers: {
    authorization: `Bearer ${accessToken}`
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
